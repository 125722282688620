<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_366_22)">
  <mask id="mask0_366_22" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <path d="M24 0H0V24H24V0Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_366_22)">
  <path d="M24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12Z" fill="#1185FE"/>
  </g>
  <path d="M7.75145 7.30926C9.47116 8.59796 11.3209 11.2109 12 12.6132C12.6792 11.211 14.5289 8.59793 16.2486 7.30926C17.4895 6.37939 19.5 5.6599 19.5 7.94934C19.5 8.40657 19.2374 11.7903 19.0833 12.3397C18.5479 14.2495 16.5969 14.7367 14.8614 14.4418C17.895 14.9572 18.6667 16.6643 17.0001 18.3714C13.8348 21.6134 12.4507 17.5579 12.0959 16.5187C12.0309 16.3282 12.0004 16.2391 12 16.3149C11.9995 16.2391 11.9691 16.3282 11.9041 16.5187C11.5494 17.5579 10.1653 21.6135 6.99992 18.3714C5.33327 16.6643 6.10496 14.9571 9.13862 14.4418C7.40308 14.7367 5.452 14.2495 4.91666 12.3397C4.76262 11.7903 4.5 8.40652 4.5 7.94934C4.5 5.6599 6.51056 6.37939 7.75136 7.30926H7.75145Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_366_22">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
